import {
  GET_ALL_MANIFESTS,
  GET_ALL_MANIFESTS_SUCCESS,
  GET_ALL_MANIFESTS_ERROR,
  SET_MANIFESTS_PAGE_SIZE,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_ERROR,
  GET_LABELS_FOR_MANIFEST,
  GET_LABELS_FOR_MANIFEST_SUCCESS,
  GET_LABELS_FOR_MANIFEST_ERROR,
  SET_CREATE_MANIFEST_DATA,
  SET_INITIAL_CREATE_FORM_DATA,
  CREATE_MANIFEST,
  CREATE_MANIFEST_SUCCESS,
  CREATE_MANIFEST_ERROR,
  GETTING_MANIFEST,
  GETTING_MANIFEST_SUCCESS,
  GETTING_MANIFEST_ERROR,
  UPDATE_MANIFEST_FIELD,
  EDITING_MANIFEST,
  EDIT_MANIFEST_SUCCESS,
  EDIT_MANIFEST_ERROR,
  CLEAR_UPDATE_MANIFEST_FIELDS,
  CLEAR_MANIFEST_ADDRESS,
  ADDING_LABELS_TO_MANIFEST,
  ADDING_LABELS_TO_MANIFEST_SUCCESS,
  ADDING_LABELS_TO_MANIFEST_ERROR,
  SET_ADDED_LABELS,
  DELETE_MANIFEST,
  DELETE_MANIFEST_SUCCESS,
  DELETE_MANIFEST_FAILURE,
  CLOSE_MANIFEST,
  CLOSE_MANIFEST_SUCCESS,
  CLOSE_MANIFEST_FAILURE,
  SET_MANIFESTS_LABELS_PAGE_SIZE,
} from "../../constants/types";

import instance from "../../services/axiosConfig";
import axios from "axios";
import { paginationHandler, parseError } from "./ActionHelpers";

const CancelToken = axios.CancelToken;
let cancel;

export const getAllManifests =
  (pageSize = 10) =>
  (dispatch) => {
    dispatch({ type: GET_ALL_MANIFESTS });

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(`v1/manifests/?page_size=${pageSize}`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_MANIFESTS_SUCCESS,
          payload: paginationHandler(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: GET_ALL_MANIFESTS_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const getManifestsByPage =
  (onSuccess, pageNo, pageSize = 10) =>
  (dispatch) => {
    dispatch({ type: GET_ALL_MANIFESTS });

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(`v1/manifests/?page_size=${pageSize}&page=${pageNo}`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_MANIFESTS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: GET_ALL_MANIFESTS_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const setManifestsPageSize = (size) => (dispatch) => {
  dispatch({
    type: SET_MANIFESTS_PAGE_SIZE,
    payload: size,
  });
};

export const setManifestLabelsPageSize = (size) => (dispatch) => {
  dispatch({
    type: SET_MANIFESTS_LABELS_PAGE_SIZE,
    payload: size,
  });
};

export const getDrivers = (onSuccess) => (dispatch) => {
  instance
    .get(`v1/drivers`)
    .then((res) => {
      dispatch({
        type: GET_DRIVERS_SUCCESS,
        payload: { drivers: res.data },
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      const errMsg = parseError(err);
      dispatch({
        type: GET_DRIVERS_ERROR,
        payload: { errors: errMsg },
      });
    });
};

export const getEligibleLabels =
  (pageSize = 10, filters = "", search = "") =>
  (dispatch) => {
    dispatch({ type: GET_LABELS_FOR_MANIFEST });
    let searchURL = "";
    if (search !== "") {
      searchURL = "&search=" + search;
    }

    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(
        `v1/manifest-eligible-labels?page_size=${pageSize}${filterURL}${searchURL}`,
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_LABELS_FOR_MANIFEST_SUCCESS,
          payload: paginationHandler(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: GET_LABELS_FOR_MANIFEST_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const getEligibleLabelsByPage =
  (pageNo, pageSize = 10, filters = "", search = "") =>
  (dispatch) => {
    dispatch({ type: GET_LABELS_FOR_MANIFEST });
    let searchURL = "";
    if (search !== "") {
      searchURL = "&search=" + search;
    }

    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(
        `v1/manifest-eligible-labels?page_size=${pageSize}&page=${pageNo}${filterURL}${searchURL}`,
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_LABELS_FOR_MANIFEST_SUCCESS,
          payload: paginationHandler(res.data),
        });
      })
      .catch((err) => {
        const errMsg = parseError(err);
        dispatch({
          type: GET_LABELS_FOR_MANIFEST_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const createManifestForm = (field, value) => async (dispatch) => {
  await dispatch({
    type: SET_CREATE_MANIFEST_DATA,
    payload: {
      field,
      value,
    },
  });
};

export const setEditManifestField = (field, value) => async (dispatch) => {
  await dispatch({
    type: UPDATE_MANIFEST_FIELD,
    payload: {
      field,
      value,
    },
  });
};

export const clearEditedData = () => async (dispatch) => {
  await dispatch({
    type: CLEAR_UPDATE_MANIFEST_FIELDS,
  });
};

export const setInitialManifestForm = () => async (dispatch) => {
  await dispatch({
    type: SET_INITIAL_CREATE_FORM_DATA,
  });
};

export const sendCreateManifestRequest = (data, onSuccess) => (dispatch) => {
  dispatch({ type: CREATE_MANIFEST });
  const payload = {
    label_ids: data.selected_labels,
    driver: data.driver,
    address_id: data.manifest_address.id,
    metadata: data.note ? data.note : null,
  };

  instance
    .post(`v1/manifests/`, payload)
    .then((res) => {
      dispatch({
        type: CREATE_MANIFEST_SUCCESS,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      const errMsg = parseError(err);
      dispatch({
        type: CREATE_MANIFEST_ERROR,
        payload: { manifest_errors: errMsg },
      });
    });
};

export const getManifest = (id) => (dispatch) => {
  dispatch({ type: GETTING_MANIFEST });
  instance
    .get(`v1/manifests/${id}`)
    .then((res) => {
      dispatch({
        type: GETTING_MANIFEST_SUCCESS,
        payload: { manifest_details: res.data },
      });
    })
    .catch((err) => {
      const errMsg = parseError(err);
      dispatch({
        type: GETTING_MANIFEST_ERROR,
        payload: { errors: errMsg },
      });
    });
};

export const editManifest = (id, data, onSuccess) => (dispatch) => {
  dispatch({ type: EDITING_MANIFEST });
  instance
    .patch(`v1/manifests/${id}`, data)
    .then((res) => {
      dispatch({
        type: EDIT_MANIFEST_SUCCESS,
        payload: {},
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      const errMsg = parseError(err);
      dispatch({
        type: EDIT_MANIFEST_ERROR,
        payload: { errors: errMsg },
      });
    });
};

export const clearSelectedAddress = () => (dispatch) => {
  dispatch({ type: CLEAR_MANIFEST_ADDRESS });
};

export const addLabelsToManifest =
  (manifest_id, new_labels, onSuccess) => (dispatch) => {
    dispatch({ type: ADDING_LABELS_TO_MANIFEST });

    instance
      .get(`v1/manifests/${manifest_id}`)
      .then((res) => {
        const already_saved_labels = res.data.labels;
        const old_labels = [];
        for (const label of already_saved_labels) {
          old_labels.push(label.label.id);
        }
        const payload = {
          label_ids: [...new_labels, ...old_labels],
        };
        instance
          .patch(`v1/manifests/${manifest_id}`, payload)
          .then((res) => {
            dispatch({
              type: ADDING_LABELS_TO_MANIFEST_SUCCESS,
              payload: {},
            });
            if (onSuccess) onSuccess();
          })
          .catch((err) => {
            dispatch({
              type: ADDING_LABELS_TO_MANIFEST_ERROR,
              payload: { errors: err },
            });
          });
      })
      .catch((err) => {
        const errMsg = parseError(err);
        dispatch({
          type: GETTING_MANIFEST_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const setAddedLabels = (value) => async (dispatch) => {
  await dispatch({
    type: SET_ADDED_LABELS,
    payload: {
      added_labels: value,
    },
  });
};

export const deleteManifest = (id, onSuccess) => (dispatch) => {
  dispatch({
    type: DELETE_MANIFEST,
  });
  instance
    .delete(`v1/manifests/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_MANIFEST_SUCCESS,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      const errMsg = parseError(err);
      console.log(err);
      dispatch({
        type: DELETE_MANIFEST_FAILURE,
        payload: errMsg,
      });
    });
};

export const closeManifest = (id, onSuccess) => (dispatch) => {
  dispatch({
    type: CLOSE_MANIFEST,
  });
  instance
    .patch(`shipshap-carrier/manifests/${id}/move`)
    .then((res) => {
      dispatch({
        type: CLOSE_MANIFEST_SUCCESS,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      const errMsg = parseError(err);
      console.log(err);
      dispatch({
        type: CLOSE_MANIFEST_FAILURE,
        payload: errMsg,
      });
    });
};
