import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { PasswordForgetLink } from "./PasswordForget";

import { ReactComponent as BoxLogo } from "../assets/box-in-logo-green.svg";
import { ReactComponent as BoxLogoWhite } from "../assets/box-in-logo.svg";
import { ReactComponent as GlobeVisual } from "../assets/auth-globe.svg";
import { ReactComponent as GoogleLogo } from "../assets/google-logo.svg";
import { Spinner } from "react-bootstrap";
import { auth } from "../services/firebase";
import history from "../History";
import { useEffect } from "react";
import { FiInfo } from "react-icons/fi";
import { ReactComponent as LinkSent } from "../assets/send-verification.svg";
import { connect } from "react-redux";
import { logOutUser } from "./actions";

const VerifyEmail = (props) => {
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        history.replace(ROUTES.HOME);
        return;
      }
    });
  }, []);

  return (
    <div className="auth verify d-flex flex-row">
      <div
        className={
          "col-12 col-lg-5 d-none d-lg-flex flex-column align-items-center justify-content-between"
        }
      >
        <Link
          className="header d-flex flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogo />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>

        <h1
          className={
            "visual col-8 text-center d-flex flex-row align-items-center"
          }
        >
          Let us know we’ve got the right email.
        </h1>

        {/*<div className={'why d-flex flex-row align-items-start'}>*/}
        {/*    <FiInfo size={25} className={'mr-3'}/>*/}
        {/*    <p>Why we need this Why we need this Why we need this Why we need this</p>*/}

        {/*</div>*/}
      </div>
      <div className="patch col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-left justify-content-around">
        <Link
          className="header d-flex d-lg-none flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogoWhite />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>
        <div className="box container col-10 col-lg-8 d-flex flex-column justify-content-center align-items-center text-center">
          <LinkSent />
          <h3>Thanks! Check your email</h3>
          <p className={"info"}>
            A verification link has been sent to your email
            <span className={"email"}>
              <b>{props.email ? ` ${props.email}.` : ". "}</b>
            </span>
            <br />
            Click on the verification link in the email to continue to your
            account set up.
          </p>
          <a
            onClick={() => {
              props.logOutUser();
              history.replace(ROUTES.SIGN_IN);
            }}
            className={"signin"}
          >
            Go To Sign In
          </a>
        </div>
        {/*<p className={'change-email'}>Didn't receive an email?&nbsp;<Link to={ROUTES.SIGN_IN}>Edit Email</Link></p>*/}
      </div>
    </div>
  );
};
const mapStateToProps = ({ auth }) => ({
  email: auth.email || auth.user?.email || "",
});

export default connect(mapStateToProps, { logOutUser })(VerifyEmail);
