import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import image from "../assets/greenpatch.png";
import google from "../assets/googlelogo.png";
import mail from "../assets/mail.svg";
import { SignUpButton } from "./SignUp";
import { PasswordForgetLink } from "./PasswordForget";
import history from "../History";
import { connect } from "react-redux";
import {
  clearPasswordReset,
  emailChanged,
  googleLogin,
  loginUser,
  passwordChanged,
} from "./actions/AuthActions";
import "../scss/auth.scss";
import { FiAlertCircle, FiEye, FiEyeOff, FiCheck } from "react-icons/fi";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo-green.svg";
import { ReactComponent as BoxLogoWhite } from "../assets/box-in-logo.svg";
import { ReactComponent as GlobeVisual } from "../assets/auth-globe.svg";
import { ReactComponent as GoogleLogo } from "../assets/google-logo.svg";
import { Spinner } from "react-bootstrap";
import { auth } from "../services/firebase";

const SignInPage = (props) => {
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        history.replace(ROUTES.HOME);
        return;
      }
    });

    if (props.resetSent) {
      const timeout = setTimeout(() => {
        props.clearPasswordReset(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <div className="auth login d-flex flex-row">
      <div
        className={
          "col-12 col-lg-5 d-none d-lg-flex flex-column align-items-center"
        }
      >
        <Link
          className="header d-flex flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogo />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>

        <div className={"intro d-flex flex-column align-items-center"}>
          <div className={"visual d-flex flex-row align-items-center"}>
            <GlobeVisual />
          </div>

          <div className={"text-center"}>
            <p className={"info"}>
              We streamline the order fulfillment process for you and connect
              you with the best shipping options , from importing your orders,
              to printing a label, to tracking: all from the convenience of your
              device. Shipping from Africa and beyond has never been easier
            </p>
          </div>
        </div>
      </div>
      <div className="patch col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-left justify-content-around">
        <Link
          className="header d-flex d-lg-none flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogoWhite />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>
        <div className="box container col-10 col-lg-6 d-flex flex-column justify-content-center align-items-start text-left">
          <h3 className="signlabel text-center "> Sign In</h3>
          {props.error === "" && props.resetSent && (
            <div className={"success-block"}>
              <FiCheck />
              Password reset link has been sent
            </div>
          )}
          {props.error && props.error !== "" && (
            <div className={"error-block"}>
              <FiAlertCircle />
              {props.error}
            </div>
          )}
          <SignInForm form="authForm" />
          <PasswordForgetLink />
          {/*<p className="divider"><span className="text">or</span></p>*/}
          {/*<SignInWithGoogleButton gIcon='googleImg'/>*/}
          {/*<div className={'switchForm d-flex flex-row justify-content-center align-items-center'}>*/}
          {/*	<p className={'mb-0 '}>Don't have an account?<Link to={ROUTES.SIGN_UP} className={'ml-3'}>Sign*/}
          {/*		Up</Link></p>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
  showPassword: false,
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.props;

    this.props.loginUser({ email, password });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.props;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit} className={`${this.props.form}`}>
        <label htmlFor="email" className={"col-12 p-0 d-flex flex-column"}>
          Email
        </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => this.props.emailChanged(event.target.value)}
          placeholder="example@gmail.com"
        />
        {/*<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>*/}
        <label htmlFor="password" className={"col-12 p-0 d-flex flex-column"}>
          Password
        </label>
        <div className={"password-field"}>
          <input
            type={this.state.showPassword ? "text" : "password"}
            name="password"
            value={password}
            onChange={(event) => this.props.passwordChanged(event.target.value)}
            placeholder="password"
          />
          <div
            className={"show-pass-button"}
            onClick={() => {
              this.setState({ showPassword: !this.state.showPassword });
            }}
          >
            {this.state.showPassword ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>

        <button
          className="btn1"
          disabled={isInvalid || this.props.loading}
          type="submit"
        >
          {this.props.loading ? (
            <Spinner animation="border" role="status" className={"mt-0"} />
          ) : (
            "Log In"
          )}
        </button>
      </form>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
  password: auth.password,
  error: auth.error,
  loading: auth.loading,
  resetSent: auth.passwordEmailSent,
});

const SignInForm = connect(mapStateToProps, {
  loginUser,
  emailChanged,
  passwordChanged,
})(SignInFormBase);

const SignInLink = () => (
  <Link to={ROUTES.SIGN_IN}>
    <button className="btn2">Login</button>
  </Link>
);

const SignInWithGoogleButtonBase = (props) => (
  <button
    className="googlesignin"
    onClick={() => {
      props.googleLogin();
    }}
  >
    <GoogleLogo className={"mr-3"} />
    Sign in with Google
  </button>
);

const SignInWithGoogleButton = connect(null, { googleLogin })(
  SignInWithGoogleButtonBase,
);

const mapSignInPageStateToProps = ({ auth }) => ({
  resetSent: auth.passwordEmailSent,
  error: auth.error,
});
export default connect(mapSignInPageStateToProps, { clearPasswordReset })(
  SignInPage,
);

export { SignInForm, SignInLink, SignInWithGoogleButton };
