import React, { useEffect, useState } from "react";

const estabilishWhatsappConnection = (setPayloadFunction) => {
  const FB = window.FB;
  console.log("Launching signup");
  FB.login(
    function (response) {
      console.log("Got a FB response");
      console.log(response);
      if (response.authResponse) {
        const code = response.authResponse.code;
        const payload = {
          code,
          authResponse: response.authResponse,
        };
        setPayloadFunction(payload);
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      config_id: "810302434106561",
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true,
      fallback_redirect_uri:
        "https://shipshap.com/home/stores/whatsapp-fallback",
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    },
  );
};

const useScript = (src) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const initTimeout = setTimeout(() => {
      window.fbAsyncInit = function () {
        const FB = window.FB;
        FB.init({
          appId: "783513489930921",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v21.0",
        });
      };
    }, 500);
    const loadScriptTimeout = setTimeout(() => {
      const script = document.createElement("script");
      script.addEventListener("load", () => setLoaded(true));
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 1200);
    return () => {
      clearTimeout(initTimeout);
      clearTimeout(loadScriptTimeout);
    };
  }, [src]);

  return loaded;
};

const onMessage = (event, setData) => {
  console.log("Received message");
  console.log({ event });
  if (
    event.origin !== "https://www.facebook.com" &&
    event.origin !== "https://web.facebook.com"
  ) {
    return;
  }
  try {
    const data = JSON.parse(event.data);
    console.log("Received message from FB (please expand)");
    console.log(JSON.stringify(data, null, 2));
    if (data.type === "WA_EMBEDDED_SIGNUP") {
      // if user finishes the Embedded Signup flow
      if (data.event === "FINISH") {
        const { phone_number_id, waba_id } = data.data;
        console.log(
          "Phone number ID ",
          phone_number_id,
          " WhatsApp business account ID ",
          waba_id,
        );
        setData(data.data);
        // if user cancels the Embedded Signup flow
      } else if (data.event === "CANCEL") {
        const { current_step } = data.data;
        console.warn("Cancel at ", current_step);
        // if user reports an error during the Embedded Signup flow
      } else if (data.event === "ERROR") {
        const { error_message } = data.data;
        console.error("error ", error_message);
      }
    }
  } catch {
    console.log("Non JSON Responses", event.data);
  }
};

const ConnectWhatsApp = ({
  storeId,
  email,
  className,
  notifyWhatsappConnection,
}) => {
  const [eventData, setEventData] = useState(null);
  const [whatsappResponse, setWhatsappResponse] = useState(null);
  useEffect(() => {
    console.log({ a: "Sending data", storeId, eventData, whatsappResponse });
    if (!!whatsappResponse) {
      console.log({
        a: "Actually sending data",
        storeId,
        eventData,
        whatsappResponse,
      });
      notifyWhatsappConnection({
        eventData,
        fbResponse: whatsappResponse,
        storeId,
      });
    }
  }, [eventData, whatsappResponse]);

  useEffect(() => {
    const currentOnMessage = (event) => {
      console.log("Received message");
      console.log({ event, storeId });
      onMessage(event, setEventData);
    };
    console.log("Adding event listener");
    window.addEventListener("message", currentOnMessage);

    return () => {
      console.log("Removing event listener");
      window.removeEventListener("message", currentOnMessage);
    };
  }, []);
  const whatsAppAccounts =
    process.env.REACT_APP_WHATSAPP_ALLOWED?.toString().split(";") || [];
  const showWhatsAppConnect = whatsAppAccounts.includes(email);

  const scriptLoaded = useScript("https://connect.facebook.net/en_US/sdk.js");

  return showWhatsAppConnect || true ? (
    <button
      className={`connect-whatsapp-button ${className}`}
      onClick={() => estabilishWhatsappConnection(setWhatsappResponse)}
      disabled={!scriptLoaded}
    >
      Connect to WhatsApp
    </button>
  ) : (
    <></>
  );
};

export default ConnectWhatsApp;
