import { getAddress, getQuantity, toMoneyFormat } from "../common/helpers";
import moment from "moment/moment";
import { Spinner } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { ReactComponent as LocalStoreIcon } from "../../../assets/store-icon.svg";
import StatusItem from "../common/StatusItem";
import { StatusFilters, TypeFilters } from "./Wallets";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

const TransactionList = (props) => {
  const isCredit = (type) => {
    if (["credits_added", "customer_payment"].includes(type)) {
      return true;
    }
    return false;
  };

  const getState = (state) => {
    switch (state) {
      case "complete":
        return "success";
      case "pending":
        return "blue";
      case "cancelled":
        return "error";
      default:
        return "";
    }
  };

  const getDate = (date) => {
    return moment(new Date(date)).format("MMM D, YYYY, hh:mm A");
  };

  const checkFeatures = (item) => {
    if (!item.chosen_variation) return null;
    if (!item.chosen_variation.features?.length) return null;
    const features = item.chosen_variation.features.reduce(
      (featureText, feature) => {
        if (feature.slug === "default") return featureText + "";
        if (!!featureText.toString().trim()) featureText += " / ";
        return featureText + feature.user_visible_value.toString().trim();
      },
      "",
    );
    return !!features ? features : null;
  };

  const getImages = (item) => {
    if (!item) return null;

    if (item.chosen_variation?.images?.length > 0) {
      return item.variation?.images[0].image;
    }

    return null;
  };

  const getTitle = (item) => {
    if (!item) return "Item";
    if (!!item.chosen_variation?.title) return `${item.chosen_variation.title}`;
    else if (!!item?.sku) return `${item.sku}`;
    else if (!!item?.product?.external_product_id)
      return `${item.product?.external_product_id}`;
    else return "Item";
  };

  const renderOrderItems = (items) => {
    return items.map((item, idx) => {
      const features = checkFeatures(item);
      return (
        <div
          key={item.id}
          className={
            "expanded-row-details-item d-flex flex-row justify-content-between align-items-center"
          }
        >
          <div className={"flex-row justify-content-start align-items-center"}>
            {getImages(item) ? (
              <img
                src={getImages(item)}
                alt={`product image ${item.product?.external_product_id}`}
              />
            ) : null}
            <p>{`${getTitle(item)}`}</p>
            {!!features ? <p>{`${features}`}</p> : null}
          </div>
          <p>{`Quantity x${getQuantity(item)}`}</p>
        </div>
      );
    });
  };

  const transactionFormatter = (cell, row) => {
    switch (cell) {
      case "credits_added":
      case "customer_payment":
        return (
          <div className={"transaction-icon credits"}>
            <LocalStoreIcon />
          </div>
        );
      case "charge":
      case "business_payment":
      default:
        return (
          <div className={"transaction-icon debits"}>
            <FaExchangeAlt size={20} className={"green"} />
          </div>
        );
    }
  };

  const descriptionFormatter = (cell, row) => {
    return (
      <div
        className={
          "transaction-description d-flex flex-column align-items-start justify-content-start"
        }
      >
        <p>{TypeFilters[row.transaction_type]}</p>
        <p className={"info"}>{`${getDate(row.occurred_at)} ・ ${cell}`}</p>
      </div>
    );
  };

  const amountFormatter = (cell, row) => {
    return (
      <div
        className={
          "transaction-amount d-flex flex-column align-items-end justify-content-start"
        }
      >
        <p className={"amount"}>{toMoneyFormat(cell, row.original_currency)}</p>
        <StatusItem
          className={`background ${getState(row.state)}`}
          message={StatusFilters[row.state]}
        />
      </div>
    );
  };

  const expandedRow = {
    renderer: (row) => {
      const {
        transaction_type: type,
        original_amount: amount,
        original_currency: currency,
        state,
        description,
        occurred_at: date,
        charge_payment: rate_purchase,
        checkout_customer_payment: checkout,
        on_delivery_customer_payment: manifest_label,
      } = row;
      return (
        <div className={"expanded-row-details"}>
          <div
            className={
              "expanded-row-payment-details d-flex flex-row align-items-start justify-content-between"
            }
          >
            <div>
              <p>{`Balance ${isCredit(type) ? "credit" : "debit"} for amount ${toMoneyFormat(Math.abs(amount), currency)}`}</p>
              <div
                className={
                  "d-flex flex-row flex-grow-1 align-items-center justify-content-start"
                }
              >
                <p className={" info mr-2"}>{getDate(date)}</p>
              </div>
            </div>
            <div
              className={
                "d-flex flex-column align-items-end justify-content-start"
              }
            >
              <p>{description}</p>
              <StatusItem
                className={`background ${getState(state)}`}
                message={StatusFilters[state]}
              />
            </div>
          </div>
          <div
            className={
              "payment-related-details d-flex flex-row flex-wrap align-items-center justify-content-start"
            }
          >
            {!!rate_purchase && (
              <div className={"expanded-row-label-details"}>
                {!!rate_purchase.shipment?.address_from &&
                  !!rate_purchase?.shipment?.address_to && (
                    <div>
                      <p>From</p>
                      <p>{getAddress(rate_purchase.shipment.address_from)}</p>
                      <p>To</p>
                      <p>{getAddress(rate_purchase.shipment.address_to)}</p>
                    </div>
                  )}
                <div>
                  {!!rate_purchase.labels?.length > 0 && (
                    <div>
                      <p className={"info"}>Labels</p>
                      <div className={"items-list"}>
                        {rate_purchase.labels.map((label) => {
                          const labelSearch = label.tracking_number
                            ? label.tracking_number
                            : label.id;
                          return [
                            <p>{labelSearch}</p>,
                            <Link
                              to={
                                ROUTES.HOME +
                                ROUTES.LABEL +
                                `?search=${labelSearch}`
                              }
                            >
                              Go To Labels
                            </Link>,
                          ];
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!!checkout && (
              <div className={"expanded-row-order-details"}>
                <div
                  className={
                    "d-flex flex-row align-items-center justify-content-between"
                  }
                >
                  <div
                    className={
                      " d-flex flex-row align-items-center justify-content-between"
                    }
                  >
                    {!!checkout.store_logo && <img src={checkout.store_logo} />}{" "}
                    <p>{checkout.store_name}</p>
                  </div>
                  {!!checkout.order ? (
                    <p>{`Order# ${checkout.order.order_number}`}</p>
                  ) : (
                    <p className={"info"}>Order not completed</p>
                  )}
                </div>
                {!!checkout?.cart?.items?.length > 0 && (
                  <div>
                    <p className={"info"}>Checkout Items</p>
                    <div className={"items-list"}>
                      {renderOrderItems(checkout?.cart?.items)}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*{!!manifest_label&&<div className={"expanded-row-payment-collection-details"}>*/}

            {/*</div>}*/}
          </div>
        </div>
      );
    },
    expandByColumnOnly: false,
    onlyOneExpanding: true,
    className: "table-expanded-row",
    parentClassName: "table-expanded-row-container",
  };

  const columns = [
    { dataField: "transaction_type", formatter: transactionFormatter },
    { dataField: "description", formatter: descriptionFormatter },
    {
      dataField: "original_amount",
      formatter: amountFormatter,
    },
  ];

  return (
    <div className={`transactions-list `}>
      {props.errors && <p className={"text-danger"}>{props.errors}</p>}
      {props.loading ? (
        <Spinner animation={"border"} variant={"dark"} />
      ) : (
        <ToolkitProvider
          keyField="id"
          data={props.list}
          columns={columns}
          search
        >
          {(toolkitProps) => {
            if (props.search) toolkitProps.searchProps.onSearch(props.search);

            if (props.list?.length > 0) {
              return (
                <div className="d-flex flex-column m-0 p-0">
                  <BootstrapTable
                    keyField={"id"}
                    {...toolkitProps.baseProps}
                    expandRow={expandedRow}
                  />
                  {props.pagination}
                </div>
              );
            } else {
              return props.searchText && props.searchText !== "" ? (
                <div>No matching transactions found for search</div>
              ) : (
                <div>No transactions found</div>
              );
            }
          }}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default TransactionList;
