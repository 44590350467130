// import stripe from 'tipsi-stripe';
import {
  BILLED_ALWAYS,
  CLEAR_PURCHASE_STATE,
  GET_LABEL_SUCCESS,
  LABEL_CREATE_SUCCESS,
  MPESA,
  PURCHASE_LABEL,
  PURCHASE_LABEL_ERROR,
  PURCHASE_LABEL_SUCCESS,
  UPDATE_FILE_TYPE,
  UPDATE_PURCHASE_EMAIL,
} from "../../constants/types";
import { analytics } from "../../services/firebase";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";

export const updatePurchaseEmail = (email) => {
  return { type: UPDATE_PURCHASE_EMAIL, payload: email };
};

export const updateFileType = (type) => {
  return { type: UPDATE_FILE_TYPE, payload: type };
};

export const getRatePurchaseDetails = (id) => {
  return new Promise((resolve, reject) => {
    instance
      .get(`v1/shipping-labels/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const pollLabelState = async (ratePurchase, onFailed) => {
  return new Promise((resolve, reject) => {
    if (!ratePurchase) {
      reject("");
    }
    let error = "";
    const interval = setInterval(() => {
      getRatePurchaseDetails(ratePurchase)
        .then((res) => {
          let status = res.data?.state.toString().toLowerCase() || null;

          if (status && status === "complete") {
            clearInterval(interval);
            resolve(res);
          }
          if ((status && status === "failed") || res.data.errors.length > 0) {
            clearInterval(interval);
            reject(parseError(res.data.errors));
            if (onFailed) onFailed();
          }
        })
        .catch((err) => {
          error = parseError(err);
        });
    }, 2500);
    setTimeout(() => {
      clearInterval(interval);
      console.log(error);
      reject(error || "Timeout Error");
    }, 30000);
  });
};

export const purchaseRateCard = (
  {
    rate,
    email,
    promo,
    method,
    giftExchangeId,
    file_type,
    order_id,
    shipment,
    amount_to_be_collected,
  },
  card,
  billing_type,
  onSuccess,
) => {
  return async (dispatch) => {
    dispatch({ type: PURCHASE_LABEL });
    const code = promo ? promo.code : "";
    const rateRequest = { rate, email, file_type };
    if (order_id && order_id !== "") {
      rateRequest.order_id = order_id;
    }
    if (amount_to_be_collected && amount_to_be_collected > 0) {
      rateRequest.amount_to_be_collected = amount_to_be_collected;
    }
    console.log("attempting to purchase rate: with request: ", rateRequest);

    if (billing_type === BILLED_ALWAYS) {
      if (!card || !card.is_default) {
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: "Please make sure you have select a payment method",
        });
        return;
      }

      if (card.payment_method_type === MPESA && rate.currency !== "KES") {
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: "MPESA payments can only be made for KES currency rates",
        });
        return;
      }
    }

    instance
      .post("v1/shipping-labels/?wait_for_purchase=false", rateRequest)
      .then((response) => {
        console.log("Creating label: ", response);
        dispatch({
          type: PURCHASE_LABEL_SUCCESS,
          payload: response.data.id,
        });
        pollLabelState(response.data.id, () => {
          dispatch(clearPurchaseStates());
        })
          .then((res) => {
            purchaseRateSuccess(dispatch, res, method, onSuccess);
          })
          .catch((err) => {
            const errMsg = parseError(err);
            dispatch({
              type: PURCHASE_LABEL_ERROR,
              payload: errMsg,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        const errMsg = parseError(err);
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: errMsg,
        });
      });
  };
};

export const checkPendingRatePurchase =
  (id, method, onSuccess) => (dispatch) => {
    dispatch({ type: PURCHASE_LABEL });
    pollLabelState(id, () => {
      dispatch(clearPurchaseStates());
    })
      .then((res) => {
        purchaseRateSuccess(dispatch, res, method, onSuccess);
      })
      .catch((err) => {
        const errMsg = parseError(err);
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: errMsg,
        });
      });
  };

const purchaseRateFailed = (dispatch, error, method) => {
  console.log("got an error while purchasing label: ");
  console.log(error);
  //   if (method === 'apple') {
  //     stripe.cancelNativePayRequest();
  //   }

  dispatch({
    type: PURCHASE_LABEL_ERROR,
    payload:
      "Error occurred while processing your payment - please try again later",
  });

  analytics().logEvent(PURCHASE_LABEL_ERROR, { error: "uncaught exception" });
};

const purchaseRateSuccess = (dispatch, { data }, method, onSuccess) => {
  if (data != null) {
    dispatch({ type: GET_LABEL_SUCCESS, payload: data });
    dispatch({
      type: LABEL_CREATE_SUCCESS,
      payload: { promotion: data.promotion },
    });
    console.log("create label successfully: ");
    onSuccess(data);
    // if (method === 'apple') {
    //   stripe.completeNativePayRequest();
    // }
    //  analytics().logEvent(LABEL_CREATE_SUCCESS, { rate: data.label.rate, method });
  } else {
    // if (method === 'apple') {
    //   stripe.cancelNativePayRequest();
    // }
    const errors =
      "errors" in data
        ? data.errors
        : "Error occurred while processing your payment - please try again later";
    dispatch({
      type: PURCHASE_LABEL_ERROR,
      payload: errors,
    });
    analytics().logEvent(PURCHASE_LABEL_ERROR, { method });
  }
};

export const clearPurchaseStates = () => (dispatch) => {
  dispatch({
    type: CLEAR_PURCHASE_STATE,
  });
};
