import React, { useState, useEffect } from "react";
import { tableDateFormatter } from "../common/helpers";
import { Spinner } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import {
  expandedRow,
  tableTrackingFormatter,
  tableFromAddressFormatter,
  tableToAddressFormatter,
  tableToMoneyFormatter,
} from "./helpers";

export const LabelsList = ({
  labels,
  loading,
  errors,
  labelDesc,
  toggleLabelDesc,
  setter,
  pagination,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isAlreadySelected = prevSelectedRows.includes(id);

      const newSelectedRows = isAlreadySelected
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id];

      setter(newSelectedRows);
      return newSelectedRows;
    });
  };

  const columns = [
    {
      dataField: "checkbox",
      text: "",
      formatter: (cellContent, row, rowIndex) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row.label.id)}
        />
      ),
      headerStyle: { width: "50px", textAlign: "center" },
    },
    {
      dataField: "label.tracking_status.event_type",
      text: "Tracking Status",
      formatter: tableTrackingFormatter,
    },
    {
      dataField: "label.created_at",
      text: "Created at",
      formatter: tableDateFormatter,
      sort: true,
      sortCaret: () => {
        return labelDesc ? <FiArrowUp /> : <FiArrowDown />;
      },
      sortFunc: () => {
        toggleLabelDesc();
      },
    },
    {
      dataField: "shipment.address_from",
      text: "From",
      formatter: tableFromAddressFormatter,
    },
    {
      dataField: "shipment.address_to",
      text: "To",
      formatter: tableToAddressFormatter,
    },
    {
      dataField: "cash_on_delivery",
      text: "Cash On Delivery",
      formatter: tableToMoneyFormatter,
    },
  ];

  if (loading) {
    return <Spinner animation="border" role="status" />;
  }

  if (labels && labels.length > 0) {
    return (
      <ToolkitProvider
        keyField="id"
        data={labels}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <BootstrapTable {...props.baseProps} classes={""} />
            {pagination}
          </div>
        )}
      </ToolkitProvider>
    );
  } else {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center text-center p-5">
        <p className="empty-manifests">
          There is no label currently available yet. <br></br>
          Start creating your label by clicking the button.
        </p>
        <Link
          to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
          className="ship-label-button d-flex m-3"
        >
          Create Label
        </Link>
      </div>
    );
  }
};
