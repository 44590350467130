import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getManifest,
  getDrivers,
  setEditManifestField,
  editManifest,
  clearEditedData,
  clearSelectedAddress,
  closeManifest,
} from "../../actions/ManifestActions";
import { Spinner, Dropdown, Form } from "react-bootstrap";
import { dateFilter, toMoneyFormat } from "../common/helpers";
import {
  formatStatus,
  formatName,
  formatAddress,
  formatDeliveryTime,
  labelTrackingStatus,
  statusDescriptionFormatter,
  expandedRow,
  DriverComponent,
  ErrorBlock,
  tableToAddressFormatter,
} from "./helpers";
import { TbHelmet, TbPackageOff } from "react-icons/tb";
import { FiMapPin, FiEdit2, FiTrash2 } from "react-icons/fi";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import MessageBlock from "../common/MessageBlock";
import history from "../../../History";
import { FormField } from "../common/FormField";
import AddressListModal from "../Address/AddressListModal";
import { EDIT_MANIFEST } from "../../../constants/types";
import { clearSelectedManifest } from "../../actions";
import toast from "react-hot-toast";
import { parseError } from "../../actions/ActionHelpers";
import { FaRegStickyNote } from "react-icons/fa";

const ManifestDetails = ({
  loading,
  details,
  getManifest,
  getDrivers,
  loadingDrivers,
  driversErrors,
  drivers,
  manifestAddress,
  setEditManifestField,
  clearSelectedManifest,
  updatedData,
  editManifest,
  clearEditedData,
  clearSelectedAddress,
  editManifestError,
  closeManifest,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverError, setDriverError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [manifestLabels, setManifestLabels] = useState([]);
  const [pendingLabelDeletion, setpendingLabelDeletion] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const clearErrors = () => {
    setDriverError("");
    setAddressError("");
  };

  const setter = (fieldName) => {
    return (value) => {
      clearErrors();
      setEditManifestField(fieldName, value);
    };
  };

  useEffect(() => {
    clearSelectedManifest();
    getManifest(id);
  }, []);

  useEffect(() => {
    getDrivers();
    clearEditedData();
    clearSelectedAddress();
    if (details) {
      setSelectedDriver(details.driver_name);
    }
  }, [editMode]);

  useEffect(() => {
    if (details) {
      setSelectedDriver(details.driver_name);
      setManifestLabels([...details.labels]);
    }
  }, [details]);

  const isLabelInList = (id) => {
    return manifestLabels.some((label) => label.id === id);
  };

  const labelLink = (cell, row) => {
    return (
      <Link to={ROUTES.HOME + ROUTES.LABEL + `?search=${cell}`}>{cell}</Link>
    );
  };

  const labelsColumns = [
    {
      dataField: "label.tracking_number",
      text: "Label",
      formatter: labelLink,
    },
    {
      dataField: "event_type",
      text: "Label Status",
      formatter: labelTrackingStatus,
    },
    {
      dataField: "label.shipment.address_to",
      text: "Recipient",
      formatter: tableToAddressFormatter,
    },
    {
      dataField: "label.tracking_status",
      text: "Delivery Time",
      formatter: (tracking_status, row) => {
        return formatDeliveryTime(tracking_status);
      },
    },
    {
      dataField: "event_data",
      text: "Status Description",
      formatter: statusDescriptionFormatter,
    },
    {
      dataField: "payment_amount",
      text: "Total Price",
      formatter: (cell, row) => {
        if (row.payment_amount) {
          return toMoneyFormat(row.payment_amount, row.payment_currency);
        } else {
          return "-";
        }
      },
    },
  ];

  const handleDeleteLabelFromManifest = (label) => {
    setpendingLabelDeletion(true);
    const deletedLabelID = label.id;
    setManifestLabels((prevLabels) =>
      prevLabels.filter((label) => label.id !== deletedLabelID),
    );
  };

  if (editMode) {
    labelsColumns.push({
      dataField: "delete_label",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => (
        <FiTrash2
          onClick={() => {
            handleDeleteLabelFromManifest(row);
          }}
        />
      ),
    });
  }

  const { id } = useParams();
  const canEditManifest = details?.status === "created" ? true : false;

  let address = null;
  if (manifestAddress) {
    address = formatAddress(manifestAddress);
  } else if (details?.source_address) {
    address = formatAddress(details.source_address);
  }

  let note = null;
  if (updatedData?.metadata) {
    note = updatedData.metadata;
  } else if (details?.metadata) {
    note = details.metadata.note;
  } else {
    note = "";
  }

  const handleEditManifest = (event) => {
    let payload = { ...updatedData };
    if (manifestAddress) {
      payload.address_id = manifestAddress.id;
    }
    if (pendingLabelDeletion) {
      payload.label_ids = manifestLabels.map((label) => label.label.id);
    }

    if (payload && Object.keys(payload).length > 0) {
      editManifest(details.id, payload, () => {
        getManifest(id);
        setEditMode(!editMode);
        toast("Manifest Updated");
      });
    } else {
      toast("No Changes");
      setEditMode(!editMode);
    }
  };

  const resetManifestLabels = () => {
    setpendingLabelDeletion(false);
    for (const label of details.labels) {
      if (!isLabelInList(label.id)) {
        setManifestLabels((prevLabels) => [...prevLabels, label]);
      }
    }
  };

  const renderEditForm = () => {
    return (
      <Form
        noValidate
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div className="d-flex w-100 flex-row align-content-center flex-wrap">
          <Form.Group
            controlId="manifest.driver"
            className={"d-flex flex-column col-12 col-lg-6 label"}
          >
            <Form.Label className={"d-flex flex-row align-items-start"}>
              Driver <span className={"required"}>*</span>
            </Form.Label>
            <DriverComponent
              loading={loadingDrivers}
              drivers={drivers}
              selectedDriver={selectedDriver}
              setSelectedDriver={setSelectedDriver}
              setter={setter}
            />
            {driverError && (
              <MessageBlock type={"error"} message={driverError} />
            )}
            {driversErrors && (
              <MessageBlock type={"error"} message={driversErrors} />
            )}
          </Form.Group>

          <Form.Group
            controlId="manifest.address"
            className={"d-flex flex-column col-12 col-lg-6 label"}
          >
            <Form.Label className={"d-flex flex-row align-items-start"}>
              Address <span className={"required"}>*</span>
            </Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                {address ? address : "Select an address"}
              </Dropdown.Toggle>
            </Dropdown>
            <Form.Control.Feedback type="invalid">
              {addressError && <ErrorBlock error={addressError} />}
            </Form.Control.Feedback>
          </Form.Group>
          <FormField
            controlId={"details.note"}
            className={"d-flex flex-column col-12 col-lg-6 pl-0"}
            label={"Note"}
            type={"text"}
            setter={setter("metadata")}
            placeholder={""}
            as={"textarea"}
            rows={3}
            value={note}
          />
        </div>
      </Form>
    );
  };

  const renderDetailsAction = () => {
    if (editMode) {
      return (
        <div className="d-flex flex-column col-12 p-0">
          {editManifestError && (
            <MessageBlock
              type={"error"}
              message={parseError(editManifestError)}
            />
          )}
          <div className="d-flex flex-row col-12 col-lg-12 justify-content-between p-0">
            <button
              onClick={() => {
                setEditMode(!editMode);
                resetManifestLabels();
              }}
              className="d-flex col-lg-6 col-md-5 col-sm-12 close-button mr-1"
            >
              Close
            </button>
            <button
              className="col-lg-6 col-md-5 col-sm-12 ml-1 filled"
              onClick={handleEditManifest}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else if (canEditManifest) {
      return (
        <div className="d-flex flex-row col-12 col-lg-12 justify-content-between p-0">
          <Link
            to={ROUTES.HOME + ROUTES.MANIFESTS}
            className="d-flex col-lg-6 col-md-5 col-sm-12 close-button mr-1"
          >
            Close
          </Link>
          <Link
            className="d-flex col-lg-6 col-md-5 col-sm-12 filled-button ml-1"
            to={ROUTES.HOME + ROUTES.MANIFESTS + "/" + id + ROUTES.ADD_LABELS}
          >
            Add more Labels
          </Link>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row col-12 col-lg-12 justify-content-end p-0">
          <Link
            to={ROUTES.HOME + ROUTES.MANIFESTS}
            className="d-flex col-lg-3 col-md-5 col-sm-12 m-2 close-button"
          >
            Back
          </Link>
          {["failed", "partially_delivered"].includes(details.status) && (
            <button
              onClick={() => {
                setShowConfirmation(true);
              }}
              className={"col-lg-3 col-md-5 col-sm-12 m-2"}
            >
              Close Manifest
            </button>
          )}
        </div>
      );
    }
  };

  const manifestNotFoundTimeout = useRef();

  useEffect(() => {
    if (!loading && !details) {
      manifestNotFoundTimeout.current = setTimeout(() => {
        history.replace(ROUTES.HOME + ROUTES.MANIFESTS);
      }, 3000);
      return () => {
        clearTimeout(manifestNotFoundTimeout.current);
      };
    }
  }, [loading, details]);

  const getStatus = () => {
    return details.status;
  };

  return (
    <div>
      {loading ? (
        <Spinner animation="border" role="status" />
      ) : details ? (
        <div className="settings-card manifests manifest-detail">
          <div className="card-section gap-8 head mb-2 d-flex flex-row justify-content-between align-items-start flex-wrap">
            <div className="card gap-16 w-auto flex-grow-1">
              <p>Status</p>
              <div>{formatStatus(getStatus())}</div>
            </div>
            <div className="card gap-16 w-auto flex-grow-1">
              <p>Collected</p>
              {details.scanned_at ? dateFilter(details.scanned_at) : "-"}
            </div>
            <div className="card gap-16 w-auto flex-grow-1">
              <p>Completed</p>
              {details.delivered_at ? dateFilter(details.delivered_at) : "-"}
            </div>
          </div>
          <div className={"card-section"}>
            <div className="d-flex flex-row justify-content-between">
              <h3 className="mb-3">Manifest Info</h3>
              <div className="pr-3 pb-0 icon">
                {canEditManifest && !editMode ? (
                  <FiEdit2 onClick={() => setEditMode(true)} />
                ) : (
                  ""
                )}
              </div>
            </div>
            {editMode ? (
              renderEditForm()
            ) : (
              <div className="d-flex flex-row justify-content-between align-items-start">
                <div className="card gap-8">
                  <p>
                    {formatName(
                      details.source_address.city,
                      details.created_at,
                    )}
                  </p>
                  <div className="grey-small-text d-flex flex-row align-items-center">
                    <TbHelmet className="mr-1" />
                    {details.driver_name}
                  </div>
                </div>
                <div className="card gap-8">
                  <p>{details.source_address.street1}</p>
                  <div className="grey-small-text d-flex flex-row align-items-center">
                    <FiMapPin className="mr-1" />
                    {formatAddress(details.source_address)}
                  </div>
                </div>
                {details.metadata?.note && (
                  <div className="card gap-8">
                    <p>Note</p>
                    <div className="grey-small-text d-flex flex-row align-items-center">
                      <FaRegStickyNote className="mr-1" />
                      {details.metadata.note}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={"card-section"}>
            <div className="d-flex flex-row align-items-baseline justify-content-between">
              <h3 className="mb-3">Manifest Labels ({details.label_count})</h3>
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-baseline pr-4">
                  <p className="grey-small-text pr-1">Total Weight </p>
                  {details.total_weight}
                  {details.weight_unit}
                </div>
                <div className="d-flex flex-row align-items-baseline pr-4">
                  <p className="grey-small-text pr-1">Total Price </p>
                  {toMoneyFormat(
                    details.total_amount_to_be_paid,
                    details.currency,
                  )}
                </div>
              </div>
            </div>
            <ToolkitProvider
              keyField="id"
              data={manifestLabels}
              columns={labelsColumns}
              search={{
                searchFormatted: true,
              }}
            >
              {(props) => (
                <div>
                  <BootstrapTable {...props.baseProps} classes={""} />
                </div>
              )}
            </ToolkitProvider>
          </div>
          <div
            className={
              "card-section last d-flex flex-row justify-content-end col-12 col-lg-12"
            }
          >
            {renderDetailsAction()}
          </div>
        </div>
      ) : (
        <div>
          <MessageBlock type={"error"} message="Manifest details not found" />
        </div>
      )}
      {showModal && (
        <AddressListModal
          toggleList={() => {
            setShowModal(!showModal);
          }}
          showList={showModal}
          listType={EDIT_MANIFEST}
        />
      )}
      {showConfirmation && (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => setShowConfirmation(false)}
          ></div>
          <div className="modal-content confirmation">
            <TbPackageOff />
            <div>
              <p>
                This will close the current manifest and move the failed or
                returned labels to a new manifest.
              </p>
              <p>Do you want to proceed?</p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button
                className={"delete-button"}
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  closeManifest(details.id, () => {
                    history.replace(ROUTES.HOME + ROUTES.MANIFESTS);
                    setShowConfirmation(false);
                  })
                }
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ manifests, manifestAddress }) => ({
  loading: manifests.loadingManifestDetails,
  details: manifests.manifestDetails,
  loadingDrivers: manifests.loading,
  driversErrors: manifests.errors,
  drivers: manifests.drivers,
  manifestAddress: manifestAddress,
  updatedData: manifests.updatedManifest,
  editManifestError: manifests.edit_manifest_error,
});

export default connect(mapStateToProps, {
  getManifest,
  getDrivers,
  setEditManifestField,
  clearSelectedManifest,
  editManifest,
  clearEditedData,
  clearSelectedAddress,
  closeManifest,
})(ManifestDetails);
