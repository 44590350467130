import React, { useEffect, useState } from "react";
import ManifestList from "./ManifestList";
import { Spinner } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import {
  closeManifest,
  deleteManifest,
  getAllManifests,
  getManifestsByPage,
  setManifestsPageSize,
} from "../../actions/ManifestActions";
import { setApplicationContext } from "../../actions/ApplicationContextActions";
import { TbPackageOff } from "react-icons/tb";
import history from "../../../History";

const Manifests = ({
  manifests,
  loading,
  getAllManifests,
  current,
  count,
  pageSize,
  getManifestsByPage,
  setManifestsPageSize,
  deleteManifest,
  closeManifest,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedManifest, setSelectedManifest] = useState(null);

  useEffect(() => {
    getAllManifests();
  }, [getAllManifests]);

  useEffect(() => {
    getAllManifests(pageSize);
  }, [pageSize]);

  return (
    <div>
      <div className="settings-card">
        <div className="card-section head mb-2 d-flex flex-row justify-content-between">
          <h3>Manifests</h3>
          <button
            onClick={() => {
              history.push(ROUTES.HOME + ROUTES.MANIFESTS + ROUTES.CREATE);
            }}
            className="button w-25"
          >
            Create Manifest
          </button>
        </div>
        <div className={"card-section last manifest-list"}>
          {/* TODO: errors, pagination,  */}
          {loading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <ManifestList
              manifests={manifests}
              deleteManifest={(id) => {
                deleteManifest(id, () => {
                  getManifestsByPage(null, current, pageSize);
                });
              }}
              closeManifest={(id) => {
                setSelectedManifest(id);
                setShowConfirmation(true);
              }}
              pagination={
                <Pagination
                  current={current}
                  count={count}
                  pageSize={pageSize}
                  getListByPage={(pageNo) =>
                    getManifestsByPage(null, pageNo, pageSize)
                  }
                  setPageSize={(size) => setManifestsPageSize(size)}
                />
              }
            />
          )}
        </div>
      </div>
      {showConfirmation && (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => setShowConfirmation(false)}
          ></div>
          <div className="modal-content confirmation">
            <TbPackageOff />
            <div>
              <p>
                This will close the current manifest and move the failed or
                returned labels to a new manifest.
              </p>
              <p>Do you want to proceed?</p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button
                className={"delete-button"}
                onClick={() => {
                  setShowConfirmation(false);
                  setSelectedManifest(null);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  closeManifest(selectedManifest, (id) => {
                    getManifestsByPage(null, current, pageSize);
                    setShowConfirmation(false);
                    setSelectedManifest(null);
                  })
                }
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ manifests }) => ({
  manifests: manifests.manifestsList,
  errors: manifests.errors,
  count: manifests.manifestResponse?.count,
  current: manifests.currentPage,
  loading: manifests.loading,
  pageSize: manifests.pageSize,
});

export default connect(mapStateToProps, {
  getAllManifests,
  getManifestsByPage,
  setManifestsPageSize,
  setApplicationContext,
  deleteManifest,
  closeManifest,
})(Manifests);
