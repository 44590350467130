import { forEach } from "react-bootstrap/ElementChildren";
import { auth } from "../../services/firebase";

const isNotEmpty = (obj) => {
  if (typeof obj === "string") {
    return true;
  }
  if (Array.isArray(obj)) {
    return obj.length > 0;
  }

  if (obj instanceof Object) {
    return Object.keys(obj).length > 0;
  }

  return true;
};

const getInnerElement = (obj) => {
  if (typeof obj === "string") {
    return obj;
  }

  if (Array.isArray(obj)) {
    let element = null;
    for (let i = 0; i < obj.length; i++) {
      if (isNotEmpty(obj[i])) {
        return getInnerElement(obj[i]);
      }
    }
    return "Something went wrong";
  }
  if (obj instanceof Object) {
    if (Object.keys(obj).includes("message")) return obj.message;
    else if (Object.keys(obj).includes("description")) {
      return obj.description;
    } else if (Object.keys(obj).includes("error")) return obj.error;
    return Object.keys(obj)[0] + ": " + getInnerElement(Object.values(obj)[0]);
  }

  return obj;
};
export function parseError(error) {
  if (!error) {
    return "Oops something went wrong - Please try again later or contact support";
  }

  if (error && typeof error === "string") return error;

  if (error.description && typeof error.description === "string")
    return error.description;

  if (error.details && typeof error.details === "string") return error.details;

  if (error.message && typeof error.message === "string") return error.message;

  let errObj = error;
  if (error.response?.data) {
    errObj = error.response.data;
  }

  if (typeof errObj === "string") {
    if (
      errObj.includes("DOCTYPE") ||
      errObj.includes("xml") ||
      errObj.length > 100
    ) {
      return "Oops something went wrong - Please try again later or contact support";
    }
    return errObj;
  }

  if (
    Array.isArray(errObj) ||
    (errObj instanceof Object && Object.keys(errObj).length > 0)
  ) {
    return getInnerElement(errObj);
  }

  return "Oops something went wrong - Please try again later or contact support";
}

const getCurrent = (data) => {
  if (data.next) {
    const nextURL = new URLSearchParams(new URL(data.next).search);
    if (nextURL.has("page")) {
      return parseInt(nextURL.get("page")) - 1;
    }
  }
  if (data.previous) {
    if (data.previous.indexOf("page=") === -1) return 2;

    const prevURL = new URLSearchParams(new URL(data.previous).search);
    if (prevURL.has("page")) {
      return parseInt(prevURL.get("page")) + 1;
    }
  }
  return 1;
};

export const paginationHandler = (data) => {
  if (Array.isArray(data.results)) {
    let current = getCurrent(data);

    return { list: data.results, response: data, currentPage: current };
  }
  return { list: [], response: data, currentPage: 1 };
};

export const getWebToken = async () => {
  return await auth()?.currentUser?.getIdToken();
};
